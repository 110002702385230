body {
    background-color: #ffffff;
    color: #002e33;
}

h1 {
    margin-top: 25px;
    margin-bottom: 15px;
    color: #bb4430;
}

h2 {
    margin-top: 25px;
    margin-bottom: 15px;
    color: #753327;
}

small {
    margin-left: 15px;
    color: gray;
}

.fas {
    color: #79b3b7;
}

.list-group-item:hover {
    background-color: #e7f2d7;
}

.list-group-item-btn {
    float: right;
    cursor: pointer;
    color: #074b51;
}

.list-group-item-btn:hover {
    color: #074b51;
}

.card {
    height: 500px;
    overflow-y: auto;
}

.card-header {
    background-color: #79b3b7;
}

.list-group-item {
    padding: 7px;
}

.list-group-item.active {
    background-color: #bcd9db;
    border-color: #bcd9db;
    color: #363732;
}

.scrollable-element {
    scrollbar-width: none;
  }

#listaAmbientes,
#listaSistemas,
#listaGrupos {
    position: relative;
    height: 400px;
    padding: 10px;
}


/* CONFIGURAÇOES DO LOADER */

#loader {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 250px;
    height: 250px;
    margin: -75px 0 0 -75px;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


/* Add animation to "page content" */

.animate-bottom {
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 2s;
    animation-name: animatebottom;
    animation-duration: 2s
}

@-webkit-keyframes animatebottom {
    from {
        bottom: -200px;
        opacity: 0
    }
    to {
        bottom: 0px;
        opacity: 1
    }
}

@keyframes animatebottom {
    from {
        bottom: -200px;
        opacity: 0
    }
    to {
        bottom: 0;
        opacity: 1
    }
}